exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-badmin-edit-tsx": () => import("./../../../src/pages/badmin-edit.tsx" /* webpackChunkName: "component---src-pages-badmin-edit-tsx" */),
  "component---src-pages-badmin-sync-tsx": () => import("./../../../src/pages/badmin-sync.tsx" /* webpackChunkName: "component---src-pages-badmin-sync-tsx" */),
  "component---src-pages-badmin-tsx": () => import("./../../../src/pages/badmin.tsx" /* webpackChunkName: "component---src-pages-badmin-tsx" */),
  "component---src-pages-bauth-tsx": () => import("./../../../src/pages/bauth.tsx" /* webpackChunkName: "component---src-pages-bauth-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-order-tsx": () => import("./../../../src/pages/order.tsx" /* webpackChunkName: "component---src-pages-order-tsx" */),
  "component---src-pages-photographer-tsx": () => import("./../../../src/pages/photographer.tsx" /* webpackChunkName: "component---src-pages-photographer-tsx" */)
}

